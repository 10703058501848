<template>
	<div style=" padding: 32px;">
	<div style="display: flex; flex-wrap: wrap; justify-content: space-between;background-color: #FFFFFF; padding: 4% 10%; border-radius: 16px;">
		<my-echart v-for='(item,index) in state.list'  :key="index" :item="item"></my-echart>
	</div>
	</div>
</template>

<script>
	import {
		defineComponent,
		onMounted,
		ref,
		reactive
	} from 'vue'
	import MyEchart from '../../components/MyEchart.vue'
	import api from "@/utils/request.js";
	export default defineComponent({
		name: 'dashboard',
		components:{MyEchart},
		setup() {
			
			let state = reactive({
				list:[]
			})
		
			onMounted(() => {
				api.post("/api/process/statistic_data ", {
					
						
				}).then(res => {
					console.log('res',res.data)
					let data = res.data
state.list = data
					// list.value = res.data
					// setTimeout(()=>{
					// 	let list = res.data.map(()=>{
					// 		return [{
					// 		value:parseInt( Math.random() * 1028),
					// 		name: '统计1'
					// 	},
					// 	{
					// 		value: 735,
					// 		name: '统计2'
					// 	},
					// 	{
					// 		value: 580,
					// 		name: '统计3'
					// 	},
					// 	{
					// 		value: 484,
					// 		name: '统计4'
					// 	},
					// 	{
					// 		value: 300,
					// 		name: '统计5'
					// 	},
					// 	{
					// 		value: parseInt(Math.random() * 1000),
					// 		name: '统计6'
					// 	},
					// 	{
					// 		value: parseInt(Math.random() * 1000),
					// 		name: '统计8'
					// 	}
					// ]
					// 	})
						
					// 	state.list = list
					// },100)
				})
							
		
			
			})
			const getDealt = () => {
				
			}
			return {
				state
			}
			// const echartsRef = ref(null)
			// onMounted(() => {
			// 	//初始化echarts实例init（ dom ，'主题' ，其余参数如：{ renderer:'svg' }）
			// 	const myEcharts = echarts.init(echartsRef.value)
			// 	//要操作的配置
			// 	const option = {
			// 		title: {
			// 			text: 'Referer of a Website',
			// 			subtext: 'Fake Data',
			// 			left: 'center'
			// 		},
			// 		tooltip: {
			// 			trigger: 'item'
			// 		},
			// 		legend: {
			// 			orient: 'vertical1111',
			// 			left: 'left'
			// 		},
			// 		series: [{
			// 			name: 'Access From',
			// 			type: 'pie',
			// 			radius: '50%',
			// 			data: [{
			// 					value: 1048,
			// 					name: 'Search Engine'
			// 				},
			// 				{
			// 					value: 735,
			// 					name: 'Direct'
			// 				},
			// 				{
			// 					value: 580,
			// 					name: 'Email'
			// 				},
			// 				{
			// 					value: 484,
			// 					name: 'Union Ads'
			// 				},
			// 				{
			// 					value: 300,
			// 					name: 'Video Ads'
			// 				},
			// 				{
			// 					value: 10000,
			// 					name: '111222'
			// 				}
			// 			],
			// 			emphasis: {
			// 				itemStyle: {
			// 					shadowBlur: 10,
			// 					shadowOffsetX: 0,
			// 					shadowColor: 'rgba(0, 0, 0, 0.5)'
			// 				}
			// 			}
			// 		}]
			// 	}
			// 	//设置配置
			// 	myEcharts.setOption(option)
			// })

			// return {
			// 	echartsRef
			// }
		}
	})
</script>

<style>
</style>
