<template>
	<div>
		<div class="echarts" ref="echartsRef" :style="{
		        width: '400px',
		        height: '450px'
		      }"></div>

	</div>
</template>

<script setup>
	import * as echarts from 'echarts'
	import {
		watch,
		ref,
		nextTick
	} from 'vue'
	//获取值  需要通过defineProps暴露出来
	let props = defineProps({
		item: Object
	});

	// console.log(props.item)
	const echartsRef = ref(null)
	watch(() => props.item, (newVal, oldVal) => {
		nextTick(() => {
			// console.log('newVal',JSON.parse(JSON.stringify(newVal)))
			let list = JSON.parse(JSON.stringify(newVal))
			console.log(100, list)
			list.data.forEach((item) => {
				item.value = item.number
			})
			//初始化echarts实例init（ dom ，'主题' ，其余参数如：{ renderer:'svg' }）
			const myEcharts = echarts.init(echartsRef.value)
			//要操作的配置
			const option = {
				title: {
					text: newVal.name,
					// subtext: 'Fake Data',
					left: 'center'
				},
				tooltip: {
					trigger: 'item',
					// formatter: '{b}: {c} ({d}%)'
					formatter: function(arg){
						return arg.name+':'+parseFloat((arg.value).toFixed(2)).toLocaleString('en',{ minimumFractionDigits: 2 })+' ('+Math.round(arg.percent*100)/100+'%) '}
				},

				legend: {
					// orient: 'vertica11',
					// left: 'left',
					top: '8%',
					left: 'center'
				},
				series: [{
		 		name: 'Access From',
					type: 'pie',
					radius: '50%',
					label: {
						formatter: '{b}:{c} ({d}%)'
					},
					data: list.data,
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0, 0, 0, 0.5)'
						}
					}
				}]
			}
			// console.log(2000,myEcharts)
			//设置配置
			myEcharts.setOption(option)
		})

	}, {
		immediate: true,
		deep: true
	})
</script>
