<template>
    <div style="width: 100%; height: 100%;">
        <router-view></router-view>
    </div>    
</template>
<script lang="ts" setup>

</script>
<style lang="scss" scoped>

</style>