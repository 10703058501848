
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

import Index from '@/views/index.vue'   //主页
import Home from '@/views/home/home.vue'    //首页
import Company from '@/views/home/company.vue'    //公司
import CompanyDetail from '@/views/home/companyDetail.vue'    //公告详情
import News from '@/views/home/news.vue'    //日程详情

import WorkBench from '@/views/workbench/workbench.vue'    //工作台
import ContractTable from '@/views/workbench/contractTable.vue'    //工作台--合同审核表
import TableApprove from '@/views/workbench/tableApprove.vue'    //工作台--合同审核表--审批
import TableApprove2 from '@/views/workbench/tableApprove2.vue'    //工作台--合同审核表--审批
import TableApproveState from '@/views/workbench/tableApproveState.vue'    //工作台--合同审核表--审批--审批状态
import TableMyRequest from '@/views/workbench/tableMyRequest.vue'    //工作台--合同审核表--审批--我的请求




import NewProcess from '@/views/workbench/newProcess.vue'    //新建流程
import allProcesses from '@/views/workbench/allProcesses.vue'    //所有流程

import NeedWith from '@/views/workbench/needWith.vue'    //待办事宜
import AlreadyDone from '@/views/workbench/alreadyDone.vue'    //已办事宜
import Finish from '@/views/workbench/finish.vue'    //办结事宜
import Cc from '@/views/workbench/cc.vue'    //办结事宜
import MyRequest from '@/views/workbench/myRequest.vue'    //我的请求
import MyArchive from '@/views/workbench/MyArchive.vue'    //我的归档
import MyRejection from '@/views/workbench/MyRejection.vue'    //我的驳回

import MyDraft from '@/views/workbench/MyDraft.vue'    //我的草稿


import Agency from '@/views/agency/agency.vue'    //代办

import Census from '@/views/census/census.vue'    //统计
import LeaveCensus from '@/views/census/leaveCensus.vue'    //统计--请假统计
import dataStatistics from '@/views/census/dataStatistics.vue'    //统计图
import FinanceCensus from '@/views/census/financeCensus.vue'    //统计--财务统计
import OuterCensus from '@/views/census/outerCensus.vue'    //统计--其他统计
import ProjectCensus from '@/views/census/projectCensus.vue'    //统计--项目统计
import MaterialDesignCensus from '@/views/census/materialDesignCensus.vue'    //统计--材设统计

import User from '@/views/user/user.vue'    //个人

import Login from '@/views/login/index.vue'  //登录

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    component: Index,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      // 首页，公司介绍
      {
        path: 'company',
		name:'Company',
        component: Company,
		meta:{
			 keepAlive: true,
		}
      },
      // 公告详情
      {
        path: 'companyDetail',
        component: CompanyDetail
      },
      // 日程详情
      {
        path: 'news',
        component: News
      },
      // 工作台
      {
        path: '/workbench',
        redirect: '/workbench/newProcess',
		meta:{
			 keepAlive: true,
		}
      },
	 // 新建流程
	 {
	   path: '/workbench/newProcess',
	   component: NewProcess,
		meta:{
			 keepAlive: true,
		}
	 },
	 // 待办
	 {
	   path:'/workbench/needWith',
	   component: NeedWith,
		meta:{
			 keepAlive: true,
		}
	 },
	 // 已办
	 {
	   path: '/workbench/alreadyDone',
	   component: AlreadyDone,
		meta:{
			 keepAlive: true,
		}
	 },
	 // 办结
	 {
	   path: '/workbench/finish',
	   component: Finish,
		meta:{
			 keepAlive: true,
		}
	 },
	 // 抄送
	 {
	   path: '/workbench/cc',
	   component: Cc,
		meta:{
			 keepAlive: true,
		}
	 },
	 // 我的请求
	 {
	   path: '/workbench/myRequest',
	   component: MyRequest,
		meta:{
			 keepAlive: true,
		}
	 },
	 // 我的归档
	 {
	   path: '/workbench/MyArchive',
	   component: MyArchive,
		meta:{
			 keepAlive: true,
		}
	 },
	 //我的驳回
	 {
	   path: '/workbench/MyRejection',
	   component: MyRejection,
		meta:{
			 keepAlive: true,
		}
	 },
	 //我的草稿
	 {
	   path: '/workbench/MyDraft',
	   component: MyDraft,
		meta:{
			 keepAlive: true,
		}
	 },
	  {
	    path: '/workbench/allProcesses',
		name:'allProcesses',
	    component: allProcesses,
		meta:{
			 keepAlive: true,
		}
	  },
      // 工作台子页面--新建流程， 合同表--审核
      {
        path: 'contractTable',
        component: ContractTable,
		meta:{
			 keepAlive: false,
		}
      },
      // 工作台子页面--新建流程， 合同表--审核--审批状态
      {
        path: 'tableApproveState',
        component: TableApproveState
      },
      // 工作台子页面--新建流程， 合同表--审核--我的请求
      {
        path: 'tableMyRequest',
        component: TableMyRequest
      },
      {
        path: 'tableApprove',
        component: TableApprove
      },
	  {
	    path: 'tableApprove2',
	    component: TableApprove2
	  },
      {
        path: 'agency',
        component: Agency
      },
      // 统计
      {
        path: 'census',
        redirect: '/census/leaveCensus'
      },
      {
        path: 'census',
        component: Census,
        children: [
          {
            path: 'leaveCensus',
            component: LeaveCensus
          },
		  {
		    path: 'dataStatistics',
		    component: dataStatistics
		  },
		  
          {
            path: 'financeCensus',
            component: FinanceCensus
          },
          {
            path: 'outerCensus',
            component: OuterCensus
          },
          {
            path: 'materialDesignCensus',
            component: MaterialDesignCensus
          },
          {
            path: 'projectCensus',
            component: ProjectCensus
          },

        ]
      },
      {
        path: 'user',
        component: User
      }
    ]
  },
  {
    path: '/login',
    component: Login
  }
]

// 创建路由
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 路由拦截 
router.beforeEach(function (to, from, next) {
  // 判断 登陆是否过期 ,  //并对 路由进行拦截
  if (!window.localStorage.getItem('token')) {
    if (to.path !== '/login') return next('/login')
  } else {
    if (to.path === '/login') return next('/')
  }
  next()
})

export default router