<template>
	<div id="nav">
		<router-view />
	<!-- 	<router-view v-slot="{ Component }">
		  <keep-alive>
		    <component :is="Component" />
		  </keep-alive>
		</router-view> -->
	</div>
</template>

<style lang="scss">
	* {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
	}

	body .el-table::before {
		z-index: inherit;
	}

	#nav,
	#app {
		width: 100vw;
		height: 100vh;
	}
</style>
