import axios from "axios"
import {
	ElMessage
} from "element-plus";
import {
	useRouter
} from "vue-router";

import router from "@/router"
// console.log('router', useRouter)
const instance = axios.create({
	// http://192.168.2.33:8080
	// https://www.guangzhijz.com
	baseURL: "https://www.guangzhijz.com",
	headers: {
		'token': localStorage.getItem('token'),

	},
	

	timeout: 5000,

});
// 请求配置
instance.interceptors.request.use(
	config => {

		// const token = 'token'
		// if (token) {
		// 	config.headers.token = token
		// }
		config.headers.token = localStorage.getItem('token') || ''
		return config
	},
	err => {
		return Promise.reject(err)
	}
)
//响应拦截
instance.interceptors.response.use(
	response => {
		

		if (response.data.code != 1 && response.data.code != 401) {
			console.log('失败')
			ElMessage({
				message: response.data.msg || "失败",
				type: "error"
			});
			return response.data
		} else {
			return response.data
		}

	},
	err => {
		// console.log(1111111111,Vrouter);
		
		//  console.log('err', err.response.data.code)
		// console.log('router',router)
		if (err.response.data.code == 401) {
			localStorage.clear()
			 router.push("/login");
			 // console.log(123)
		}

		return Promise.reject(err)
	}
)

export default {
	get(url, params) {
		return instance.get(url, {
			params
		})
	},
	post(url, data, config) {
		return instance.post(url, data, config)
	}

}
