import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './common/global.css'
import 'dayjs/locale/zh-cn' //中文
import locale from 'element-plus/lib/locale/lang/zh-cn'
 // import store from './store/index.js'

import App from './App.vue'
import router from './router'
import print from 'vue3-print-nb'

createApp(App).use(router).use(ElementPlus, {
    locale
}).use(print).mount('#app')
