<template>
  <div class="company-detail">
    <div class="company-detail-content">
      <h5 style="text-align: center">
        {{ book.title }}
      </h5>
      <div class="company-detail-content-info">
        <span>{{ formatTime(book.createtime) }}</span>
      </div>
      <p style="line-height: 2; font-size: 18px">
        {{ book.text }}
      </p>
    </div>
  </div>
</template>
<script >
import { useRoute } from "vue-router";
import { reactive, ref, toRefs } from "vue";
import api from "@/utils/request.js";
export default {
  setup() {
    const route = useRoute();
    /**
     * 上一页传过来的文章ID
     */
    const id = ref(route.query.id);
    const bookInfo = reactive({
      book: {},
    });

    console.log(api);
    /**
     * 获取 日程详情
     */
    async function getDetail(id) {
      const res = await api.post("/api/book/detail", { id });
      if (res.code != 1) return;
      bookInfo.book = res.data;
      console.log(res);
    }
    getDetail(id.value);

    const formatTime = (timestamp) => {
      return new Date(timestamp * 1000).toLocaleString().replaceAll("/", "-");
    };

    return {
      formatTime,
      ...toRefs(bookInfo),
    };
  },
};
</script>
<style lang="scss" scoped>
.company-detail {
  width: 100%;
  height: 100%;
  padding: 32px;
  font-size: 28px;

  .company-detail-content {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 20px;
    padding: 70px 150px;

    .company-detail-content-info {
      padding: 48px 0;
      color: #999999;
      font-size: 18px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }
}
</style>